<template>
  <div class="flex-1 flex flex-col">
    <k-grid
      v-show="!isLoaded || userGroups.length"
      v-loading="isLoading"
      :auto-group-column-def="UserGroupNameColumn"
      :grid-options="gridOptions"
      :classes="`user-groups-overview-table no-status-bar w-full flex-1`"
      :element-loading-text="t('user_management.states.loading_user_groups')"
      :row-data="userGroups"
      :side-bar="sideBar"
      :col-defs="columnDefs"
      :fixed-grid-height="true"
      data-test="user-groups-overview-table"
      @grid-ready="onGridReady"
    />
    <div v-if="!userGroups.length && isLoaded">
      <p>{{ t('user_management.states.no_user_groups') }}</p>
      <el-button id="add-user-group-btn" :icon="Plus" type="success" data-test="add-user-group-button" @click="createFirstUserGroup">
        {{ t('user_management.actions.add_user_group') }}
      </el-button>
    </div>
    <user-group-management-modal
      v-if="selectedUserGroup && showModal"
      :user-group="selectedUserGroup"
      :user-group-has-children="selectedUserGroupHasChildren"
      @close="showModal = false"
      @user-group-saved="onUserGroupSaved"
    />
  </div>
</template>

<script lang="ts" setup>
import { Plus } from '@element-plus/icons-vue';
import { ColDef, GridApi, GridOptions, GridReadyEvent, IRowNode, SideBarDef } from 'ag-grid-enterprise';
import { RowDragEvent } from 'ag-grid-enterprise/dist/types/core/events';
import { ElMessageBox } from 'element-plus';
import { storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { ApiError, EddyBaseError } from '@/modules/api/base-client';
import GridUserActionsRenderer from '@/modules/grid/components/GridUserActionsRenderer.vue';
import { MessageService, handleErrors } from '@/modules/shared';
import KGrid from '@/modules/shared/components/KGrid.vue';
import { UserGroup, UserGroupDefinition } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { userGroupManagementService } from '@/modules/user-management/api/user-group/user-group-management.service';
import UserGroupManagementModal from '@/modules/user-management/components/UserGroupManagementModal.vue';
import UserGroupNameCellRenderer from '@/modules/user-management/components/UserGroupNameCellRenderer.vue';
import {
  UserGroupLastModifiedByColumn,
  UserGroupLastModifiedDateColumn,
  UserGroupLeaderColumn,
  UserGroupMembersColumn,
  UserGroupMembersCountColumn,
  UserGroupNameColumn,
  generateUserActionsColumn,
} from '@/modules/user-management/models/column-definitions/user-group-management-column-definitions';
import { useUserGroupStore } from '@/modules/user-management/store/user-group.store';
import { useUserManagementStore } from '@/modules/user-management/store/user-management.store';
import { useUserStore } from '@/modules/user-settings/store/user.store';

const { t } = useI18n();

defineOptions({
  components: {
    GridUserActionsRenderer,
    UserGroupNameCellRenderer,
  },
});

const selectedUserGroup = ref<UserGroup | undefined>();
const selectedUserGroupHasChildren = computed<boolean>(() =>
  // check if the id of the user group exists in any other user group as an id in the path
  userGroups.value.some((userGroup) =>
    userGroup.path.some((path) => path.id === selectedUserGroup.value?.id && userGroup.id !== selectedUserGroup.value?.id),
  ),
);
const userStore = useUserStore();
const { authorities, fontSize } = storeToRefs(userStore);

const userManagementStore = useUserManagementStore();
const { allUsers: users } = storeToRefs(userManagementStore);

const userGroupStore = useUserGroupStore();
const { userGroups } = storeToRefs(userGroupStore);

const gridApi: Ref<GridApi<UserGroup> | undefined> = ref();
const gridOptions: Ref<GridOptions<UserGroup> | undefined> = ref({
  defaultColDef: {
    cellStyle: {
      'font-size': `${fontSize.value}px`,
      'line-height': `${fontSize.value + 9}px`,
    },
    resizable: true,
    cellClassRules: {
      'hover-over': (params) => params.node?.data?.id === potentialParent.value?.data?.id,
    },
  },
  context: {
    onAddUserGroup: (newUserGroup: UserGroup) => {
      onAddOrMoveUserGroup(newUserGroup);
    },
  },
  domLayout: 'normal',
  rowHeight: 50,
  treeData: true,
  groupDefaultExpanded: -1,
  suppressGroupRowsSticky: true,
  suppressRowClickSelection: true,
  getRowId: (params) => `${params.data?.id}`,
  getDataPath: (data: any) => data.path.map((node: { id: number; name: string }) => node.id),
  rowSelection: 'multiple',
  onRowDragEnd,
  onRowDragLeave,
  onRowDragMove,
});
const columnDefs: Ref<ColDef<UserGroup>[]> = ref([]);
const isLoading = ref(false);
const isLoaded: Ref<boolean> = ref(false);
const showModal = ref(false);
const potentialParent = ref<IRowNode<UserGroup> | undefined>();

const sideBar: ComputedRef<SideBarDef> = computed(() => ({
  toolPanels: [
    {
      id: 'columns',
      labelDefault: t('grid_side_bar.columns'),
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivotMode: true,
      },
    },
    {
      id: 'filters',
      labelDefault: t('grid_side_bar.filters'),
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {},
    },
  ],
}));

onBeforeMount(async () => {
  columnDefs.value = [
    UserGroupLeaderColumn,
    UserGroupMembersCountColumn,
    UserGroupMembersColumn,
    UserGroupLastModifiedDateColumn,
    UserGroupLastModifiedByColumn,
  ];

  if (authorities.value?.includes(Authority.UserGroupsUpdate)) {
    columnDefs.value.push({
      ...generateUserActionsColumn([
        {
          icon: ['fal', 'pen'],
          title: t('user_management.actions.edit'),
          action: onEditUserGroup,
        },
        {
          icon: ['fal', 'pen'],
          title: t('user_management.actions.edit'),
          action: onEditUserGroup,
        },
        {
          icon: ['fal', 'trash'],
          title: t('user_management.actions.delete'),
          action: onDeleteUserGroup,
          divided: true,
        },
      ]),
      headerName: t('user_management.labels.actions'),
    });
  }
  await fetchUserGroups();
});

function onGridReady(event: GridReadyEvent<UserGroup>): void {
  gridApi.value = event.api;
}

async function fetchUserGroups(forceRefresh: boolean = false): Promise<void> {
  isLoading.value = true;
  try {
    if (forceRefresh || !userGroups.value.length) {
      await userGroupStore.getUserGroups();
    }
    if (forceRefresh || !users.value.length) {
      // Also update the users because user groups got changed
      await userManagementStore.getAllUsers();
    }
    // update the grid with the new data
    gridApi.value?.setGridOption('rowData', userGroups.value);
    // refresh all cells to update the row group
    gridApi.value?.refreshCells({ force: true });
  } catch (e) {
    handleErrors(e as ApiError);
  }
  isLoading.value = false;
  isLoaded.value = true;
}

function onRowDragMove(event: RowDragEvent<UserGroup>): void {
  setPotentialParentForNode(event.api, event.node, event.overNode);
}

function onRowDragLeave(event: RowDragEvent<UserGroup>): void {
  setPotentialParentForNode(event.api, event.node, undefined);
}

function onRowDragEnd(event: RowDragEvent<UserGroup>): void {
  const movingData: UserGroup | undefined = event.node.data;
  if (!potentialParent.value || !movingData || !gridApi.value) {
    return;
  }
  // Take new parent path from parent, if data is missing, means it's the root node, which has no data.
  const newParentPath: UserGroupDefinition[] = potentialParent.value.data ? potentialParent.value.data.path : [];
  const needToChangeParent = !arePathsEqual(newParentPath, movingData.path);
  // check we are not moving a folder into a leaf userGroup or a child node which is not itself
  const invalidMove = isInvalidMove(event.node, potentialParent.value);
  if (needToChangeParent && !invalidMove) {
    const newPath = newParentPath.slice();
    newPath.push(movingData.path[movingData.path.length - 1]);
    movingData.path = newPath;
    movingData.parent = { name: potentialParent.value.data?.name as string, id: potentialParent.value.data?.id as number };
    onAddOrMoveUserGroup(movingData);
    gridApi.value.clearFocusedCell();
  }
  // clear node to highlight
  setPotentialParentForNode(event.api, event.node, undefined);
}

function setPotentialParentForNode(
  api: GridApi<UserGroup>,
  selectedNode: IRowNode<UserGroup | undefined>,
  overNode: IRowNode<UserGroup | undefined> | undefined,
): void {
  const newPotentialParent: IRowNode<UserGroup> | undefined = overNode?.data ? (overNode as IRowNode<UserGroup>) : undefined;
  if (potentialParent.value?.data?.id === newPotentialParent?.data?.id) {
    return;
  }
  // check we are not moving a folder into a leaf userGroup or a child node which is not itself
  const invalidMove = isInvalidMove(selectedNode, newPotentialParent);
  if (invalidMove) {
    return;
  }

  // we refresh the previous selection (if it exists) to clear
  // the highlighted and then the new selection.
  const rowsToRefresh: IRowNode<UserGroup>[] = [];
  if (potentialParent.value) {
    rowsToRefresh.push(potentialParent.value);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent.value = newPotentialParent;
  refreshRows(api, rowsToRefresh);
}

function arePathsEqual(path1: UserGroupDefinition[], path2: UserGroupDefinition[]): boolean {
  if (path1.length !== path2.length) {
    return false;
  }
  let equal = true;
  path1.forEach(function (item, index) {
    if (path2[index].id !== item.id) {
      equal = false;
    }
  });
  return equal;
}

function isInvalidMove(selectedNode: IRowNode<UserGroup | undefined>, targetNode: IRowNode<UserGroup> | undefined): boolean {
  // return based on the following conditions:
  // 1. if the selected node or target node is missing, the move is valid
  // 2. if the selected node and target node are the same, the move is valid
  // 3. if the target node is a leaf, the move is invalid
  // 4. if the selected node is a parent of the target node, the move is invalid
  if (!selectedNode.data || !targetNode?.data) return false;
  if (selectedNode.data.id === targetNode.data.id) return false;
  if (targetNode.data.isLeaf) return true;
  return isSelectionParentOfTarget(selectedNode, targetNode);
}

function moveToPath(newParentPath: UserGroupDefinition[], node: IRowNode<UserGroup>, allUpdatedNodes: UserGroup[]): void {
  if (!node.data) {
    return;
  }
  const oldPath = node.data.path;
  const newChildPath = newParentPath.slice();
  newChildPath.push(oldPath[oldPath.length - 1]);
  node.data.path = newChildPath;
  // if the old node becomes a child of its onw children, we need to remove the original node from the children.
  if (node.parent && node.parent?.data?.id === node.data.id) {
    node.parent.childrenAfterGroup = node.parent?.childrenAfterGroup?.filter((child) => child.data?.id !== node.data?.id) || [];
  }

  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach((childNode) => {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
}

function isSelectionParentOfTarget(
  selectedNode: IRowNode<UserGroup | undefined>,
  targetNode: IRowNode<UserGroup | undefined> | undefined,
): boolean {
  if (!selectedNode.data || !targetNode?.data) return false;
  const selectedPath = selectedNode.data.path;
  const targetPath = targetNode.data.path;
  if (selectedPath.length >= targetPath.length) return false;
  return selectedPath.every((item, index) => item.id === targetPath[index].id);
}

function refreshRows(api: GridApi<UserGroup>, rowsToRefresh: IRowNode<UserGroup>[]): void {
  api.refreshCells({
    // refresh these rows only.
    rowNodes: rowsToRefresh, // Because the grid does change detection, the refresh
    // will not happen because the underlying value has not
    // changed. to get around this, we force the refresh,
    // which skips change detection.
    force: true,
  });
}

// This adds a new same or nested level user group in the grid
async function onAddOrMoveUserGroup(userGroup: UserGroup): Promise<void> {
  try {
    await userGroupManagementService.editUserGroup(userGroup);
    await fetchUserGroups(true);
  } catch (error) {
    handleErrors(error as EddyBaseError);
  }
}

async function createFirstUserGroup(): Promise<void> {
  const initialUserGroup: UserGroup = {
    name: '',
    path: [],
    alias: '',
    members: [],
    isLeaf: false,
  };
  await onEditUserGroup(initialUserGroup);
}

// This opens the modal to start editing a user group
async function onEditUserGroup(editUserGroup: UserGroup): Promise<void> {
  selectedUserGroup.value = editUserGroup;
  showModal.value = true;
}

// This updates the userGroup list after a userGroup has been saved in the sidebar
async function onUserGroupSaved(): Promise<void> {
  await fetchUserGroups(true);
  selectedUserGroup.value = undefined;
}

async function onDeleteUserGroup(deleteUserGroup: UserGroup): Promise<void> {
  ElMessageBox.confirm(
    t('user_management.info.confirm_user_group_deletion'),
    t('user_management.titles.confirm_user_group_deletion', { userGroup: deleteUserGroup.name }),
    {
      confirmButtonText: t('user_management.actions.delete_group'),
      type: 'warning',
    },
  ).then(async () => {
    try {
      await userGroupManagementService.deleteUserGroup(deleteUserGroup);
      MessageService.success(t('user_management.states.user_group_deleted'));
      await fetchUserGroups(true);
    } catch {
      MessageService.error(t('user_management.states.user_group_not_deleted'));
    }
  });
}

defineExpose({ isInvalidMove, isSelectionParentOfTarget, moveToPath, setPotentialParentForNode, potentialParent });
</script>
<style lang="scss" scoped>
:deep(.user-groups-overview-table) {
  .hover-over {
    background-color: #8d99ff;
  }

  .ag-cell-wrapper.ag-row-group {
    align-items: center;
  }
}
</style>
