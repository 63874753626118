import { CellClassParams, ColDef, ColGroupDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise';

import { FlightLineColumnsGeneratorValuesInterface } from '@/models/columns/collections/flightlines';
import { NumberColumnFilterSettings, SetColumnFilterSettings } from '@/models/columns/definitions/base';
import { InventoryTacticCabinNegotiatedAllocationsColumn } from '@/models/columns/definitions/cabin-inventory-tactic';
import { RequiredPermission } from '@/models/columns/utils/col-def-with-permission';
import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { CabinCode, CabinStructure } from '@/modules/api/application/application-contracts';
import {
  FlightLineCabin,
  FlightLineModel,
  TO_CONTRACT_TYPES,
  TO_CONTRACT_TYPE_VALUES,
  TourOperatorDetailsByOperatorName,
} from '@/modules/api/flight/flight-contracts';
import { seatAvailabilityFormatter } from '@/modules/grid/formatters/seat-availability.formatter';
import { AssignableTourOperatorContract } from '@/modules/tour-operator-details/api/single-inventory-management.service';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';
const { t } = i18n.global;

// A tour-operator Details Button that opens up the popup
export const generateTourOperatorDetailsColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorDetails) : ColumnId.TourOperatorDetails,
  headerName: t('grid.columns.tour_operator.details_short'),
  headerClass: 'ag-left-aligned-header',
  headerTooltip: t('grid.columns.tour_operator.details'),
  minWidth: 70,
  width: 70,
  cellRendererParams: () => ({
    cabinCode,
  }),
  cellRenderer: 'GridTourOperatorDetailsRenderer',
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-details-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
});

// Confirmed
export const generateTourOperatorConfirmedColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorConfirmed) : ColumnId.TourOperatorConfirmed,
  headerName: t('grid.columns.tour_operator.confirmed_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.confirmed'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-confirmed-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.confirmed;
    } else {
      return params.data?.tourOperatorKpis?.confirmed;
    }
  },
});

// Sold
export const generateTourOperatorSoldColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorSold) : ColumnId.TourOperatorSold,
  headerName: t('grid.columns.tour_operator.sold_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.sold'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-sold-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.sold;
    } else {
      return params.data?.tourOperatorKpis?.sold;
    }
  },
});

// Available // calculated (Confirmed – Sold)
export const generateTourOperatorAvailableColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorAvailable) : ColumnId.TourOperatorAvailable,
  headerName: t('grid.columns.tour_operator.available_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.available'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-available-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.available;
    } else {
      return params.data?.tourOperatorKpis?.available;
    }
  },
});

// Initial Utilization // calculated (Sold / Initial)
export const generateTourOperatorInitialUtilizationColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode
    ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorInitialUtilization)
    : ColumnId.TourOperatorInitialUtilization,
  headerName: t('grid.columns.tour_operator.initial_utilization_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.initial_utilization'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-initial-utilization-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.initialUtilization;
    } else {
      return params.data?.tourOperatorKpis?.initialUtilization;
    }
  },
});

// Confirmed Utilization // calculated (Sold / Confirmed)
export const generateTourOperatorConfirmedUtilizationColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode
    ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorConfirmedUtilization)
    : ColumnId.TourOperatorConfirmedUtilization,
  headerName: t('grid.columns.tour_operator.confirmed_utilization_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.confirmed_utilization'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-confirmed-utilization-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.confirmedUtilization;
    } else {
      return params.data?.tourOperatorKpis?.confirmedUtilization;
    }
  },
});

// Confirmed Rate
export const generateTourOperatorConfirmedRateColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorConfirmedRate) : ColumnId.TourOperatorConfirmedRate,
  headerName: t('grid.columns.tour_operator.confirmed_rate_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.confirmed_rate'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-confirmed-rate-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.confirmedRate;
    } else {
      return params.data?.tourOperatorKpis?.confirmedRate;
    }
  },
});

// Sold Revenue
export const generateTourOperatorSoldRevenueColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorSoldRevenue) : ColumnId.TourOperatorSoldRevenue,
  headerName: t('grid.columns.tour_operator.sold_revenue_short'),
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.sold_revenue'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-sold-revenue-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin && matchingCabin.tourOperatorKpis?.soldRevenue;
    } else {
      return params.data?.tourOperatorKpis?.soldRevenue;
    }
  },
});

// Sold Average Rate // calculated (Revenue / Sold)
export const generateTourOperatorSoldAverageRateColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorSoldAverageRate) : ColumnId.TourOperatorSoldAverageRate,
  headerName: t('grid.columns.tour_operator.sold_average_rate_short'),
  minWidth: 40,
  width: 40,
  sortable: true,
  headerTooltip: t('grid.columns.tour_operator.sold_average_rate'),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-tour-operator-sold-average-rate-${data.ondId}${cabinCode ? '-' + cabinCode : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin?.tourOperatorKpis?.soldAverageRate
        ? FormatService.roundNumber(matchingCabin.tourOperatorKpis?.soldAverageRate, 2)
        : undefined;
    } else {
      return params.data?.tourOperatorKpis?.soldAverageRate
        ? FormatService.roundNumber(params.data.tourOperatorKpis?.soldAverageRate, 2)
        : undefined;
    }
  },
});

export const generateTourOperatorSaleableLoadFactorColumn = (cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: cabinCode
    ? generateCabinLevelColumnId(cabinCode, ColumnId.TourOperatorCapacityLoadFactor)
    : ColumnId.TourOperatorCapacityLoadFactor,
  headerName: t('grid.columns.tour_operator.saleable_load_factor_short'),
  headerTooltip: t('grid.columns.tour_operator.saleable_load_factor'),
  minWidth: 40,
  width: 40,
  sortable: true,
  cellClass: ({ data }) =>
    `ag-right-aligned-cell data-test-tour-operator-capacity-load-factor-${data?.id}${cabinCode ? `-${cabinCode}` : ''}`,
  valueGetter: (params) => {
    if (cabinCode) {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return typeof matchingCabin?.tourOperatorKpis?.capacityLoadFactor === 'number'
        ? FormatService.roundNumber(matchingCabin?.tourOperatorKpis.capacityLoadFactor, 1)
        : undefined;
    } else {
      return typeof params.data?.tourOperatorKpis?.capacityLoadFactor === 'number'
        ? FormatService.roundNumber(params.data?.tourOperatorKpis.capacityLoadFactor, 1)
        : undefined;
    }
  },
  requiredPermission: ({ customerSettings }) => !!(customerSettings.hasNegoAllocationEnabled && customerSettings.hasTourOperatorsV2Enabled),
});

/**************************** Control Columns Tour Operator - Single Inventory Management ****************************/

const isTourOperatorColumnVisible: RequiredPermission = (params): boolean => !!params.customerSettings.hasSingleInventoryManagementEnabled;

const generateTourOperatorContractBasedColumnId = (id: string, type: TO_CONTRACT_TYPES, cabin?: string): string =>
  cabin ? `${cabin}-tour-operator-contract-type-${type}-${id}` : `tour-operator-contract-type-${type}-${id}`;

/**
 * @param contractType
 * @param cabinCode - optional, otherwise it will be a flight-level column
 */
const generateTourOperatorSumOfAllocationColumn = (contractType: TO_CONTRACT_TYPES, cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateTourOperatorContractBasedColumnId('sum-of-allocation', contractType, cabinCode),
  headerName: t('grid.columns.tour_operator.sum_of_allocation_short'),
  headerTooltip: t('grid.columns.tour_operator.sum_of_allocation'),
  sortable: true,
  valueGetter: ({ data }) =>
    cabinCode
      ? FlightService.getMatchedCabin(data, cabinCode)?.tourOperatorDetailsByContractType?.[contractType]?.sumOfAllocation
      : data?.tourOperatorDetailsByContractType?.[contractType]?.sumOfAllocation,
  requiredPermission: isTourOperatorColumnVisible,
});

/**
 * @param contractType
 * @param cabinCode - optional, otherwise it will be a flight-level column
 */
const generateTourOperatorSumOfSoldColumn = (contractType: TO_CONTRACT_TYPES, cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateTourOperatorContractBasedColumnId('sum-of-sold', contractType, cabinCode),
  headerName: t('grid.columns.tour_operator.sum_of_sold_short'),
  headerTooltip: t('grid.columns.tour_operator.sum_of_sold'),
  sortable: true,
  valueGetter: ({ data }) =>
    cabinCode
      ? FlightService.getMatchedCabin(data, cabinCode)?.tourOperatorDetailsByContractType?.[contractType]?.sumOfSold
      : data?.tourOperatorDetailsByContractType?.[contractType]?.sumOfSold,
  requiredPermission: isTourOperatorColumnVisible,
});

/**
 * @param contractType
 * @param cabinCode - optional, otherwise it will be a flight-level column
 */
const generateTourOperatorSumOfRevenueColumn = (contractType: TO_CONTRACT_TYPES, cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateTourOperatorContractBasedColumnId('sum-of-revenues', contractType, cabinCode),
  headerName: t('grid.columns.tour_operator.sum_of_revenue_short'),
  headerTooltip: t('grid.columns.tour_operator.sum_of_revenue'),
  sortable: true,
  valueGetter: ({ data }) =>
    cabinCode
      ? FlightService.getMatchedCabin(data, cabinCode)?.tourOperatorDetailsByContractType?.[contractType]?.sumOfRevenue
      : data?.tourOperatorDetailsByContractType?.[contractType]?.sumOfRevenue,
  valueFormatter: ({ value, data }: ValueFormatterParams<FlightLineModel, number>) =>
    typeof value !== 'number' ? '' : FormatService.amountWithoutCurrency(value, data?.fareCurrency),
  requiredPermission: isTourOperatorColumnVisible,
});

/**
 * @param contractType - Type of aggregation (PL, FX, PR)
 * @param cabinCode - optional, otherwise it will be a flight-level column
 */
const generateTourOperatorSeatAvailabilityColumn = (contractType: TO_CONTRACT_TYPES, cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateTourOperatorContractBasedColumnId('seat-availability', contractType, cabinCode),
  headerName: t('grid.columns.tour_operator.seat_availability_short'),
  headerTooltip: t('grid.columns.tour_operator.seat_availability'),
  sortable: true,
  valueGetter: ({ data }) =>
    cabinCode
      ? FlightService.getMatchedCabin(data, cabinCode)?.tourOperatorDetailsByContractType?.[contractType]?.seatAvailability
      : data?.tourOperatorDetailsByContractType?.[contractType]?.seatAvailability,
  valueFormatter: seatAvailabilityFormatter,
  requiredPermission: isTourOperatorColumnVisible,
});

/**
 * @param contractType
 * @param cabinCode - optional, otherwise it will be a flight-level column
 */
const generateTourOperatorMaterializationPercentageColumn = (
  contractType: TO_CONTRACT_TYPES,
  cabinCode?: string,
): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateTourOperatorContractBasedColumnId('materialization-percentage', contractType, cabinCode),
  headerName: t('grid.columns.tour_operator.materialization_percentage_short'),
  headerTooltip: t('grid.columns.tour_operator.materialization_percentage'),
  sortable: true,
  valueGetter: ({ data }) =>
    cabinCode
      ? FlightService.getMatchedCabin(data, cabinCode)?.tourOperatorDetailsByContractType?.[contractType]?.materializationPercentage
      : data?.tourOperatorDetailsByContractType?.[contractType]?.materializationPercentage,
  valueFormatter: ({ value }) => FormatService.roundNumber(value, 1),
  requiredPermission: isTourOperatorColumnVisible,
});

/**
 * @param contractType
 * @param cabinCode - optional, otherwise it will be a flight-level column
 */
const generateTourOperatorAverageFareColumn = (contractType: TO_CONTRACT_TYPES, cabinCode?: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateTourOperatorContractBasedColumnId('average-fare', contractType, cabinCode),
  headerName: t('grid.columns.tour_operator.average_fare_short'),
  headerTooltip: t('grid.columns.tour_operator.average_fare'),
  sortable: true,
  valueGetter: ({ data }) =>
    cabinCode
      ? FlightService.getMatchedCabin(data, cabinCode)?.tourOperatorDetailsByContractType?.[contractType]?.averageFare
      : data?.tourOperatorDetailsByContractType?.[contractType]?.averageFare,
  valueFormatter: ({ value, data }: ValueFormatterParams<FlightLineModel, number>) =>
    typeof value !== 'number' ? '' : FormatService.amountWithoutCurrency(value, data?.fareCurrency),
  requiredPermission: isTourOperatorColumnVisible,
});

/**
 * Grouped columns for tour operator contract aggregation.
 */
const generateTourOperatorColumnsByContractType = (contractType: TO_CONTRACT_TYPES, cabinCode?: string): ColDef<FlightLineModel>[] => [
  generateTourOperatorSumOfAllocationColumn(contractType, cabinCode),
  generateTourOperatorSumOfSoldColumn(contractType, cabinCode),
  generateTourOperatorSumOfRevenueColumn(contractType, cabinCode),
  generateTourOperatorSeatAvailabilityColumn(contractType, cabinCode),
  generateTourOperatorMaterializationPercentageColumn(contractType, cabinCode),
  generateTourOperatorAverageFareColumn(contractType, cabinCode),
];

const TOUR_OPERATOR_CABIN_LEVEL_GROUP_ID_PREFIX = 'flight-cabin-level-tour-operator-contract-aggregation';
const TOUR_OPERATOR_FLIGHT_LEVEL_GROUP_ID_PREFIX = 'flight-level-tour-operator-contract-aggregation';

/**
 * Convenience method to generate all columns for a all contract types on flight level.
 */
const generateTourOperatorContractAggregationFlightLevelColumns = (): ColGroupDef<FlightLineModel> => ({
  headerName: `${t('general.flight')} | ${t('grid.columns.tour_operator.tour_operator_contract_aggregations')}`,
  headerTooltip: `${t('general.flight')} | ${t('grid.columns.tour_operator.tour_operator_contract_aggregations')}`,
  groupId: `${TOUR_OPERATOR_FLIGHT_LEVEL_GROUP_ID_PREFIX}`,
  requiredPermission: isTourOperatorColumnVisible,
  children: Object.values(TO_CONTRACT_TYPE_VALUES).flatMap((contractType) => ({
    headerName: contractType,
    headerTooltip: `${t('grid.columns.tour_operator.contract_type.' + contractType)}`,
    groupId: `${TOUR_OPERATOR_FLIGHT_LEVEL_GROUP_ID_PREFIX}-${contractType}`,
    children: generateTourOperatorColumnsByContractType(contractType),
  })),
});

/**
 * Convenience method to generate all columns for a all contract types on cabin level for specifically for the inventory tactics grid.
 */
export const generateTourOperatorContractAggregationInventoryGridLevelColumns = (): ColGroupDef<FlightViewLegCabinInventoryTactic> => ({
  headerName: `${t('general.flight')} | ${t('cabin')} |  ${t('grid.columns.tour_operator.tour_operator_contract_aggregations')}`,
  headerTooltip: `${t('general.flight')} | ${t('cabin')} |  ${t('grid.columns.tour_operator.tour_operator_contract_aggregations')}`,
  groupId: `${TOUR_OPERATOR_FLIGHT_LEVEL_GROUP_ID_PREFIX}`,
  requiredPermission: isTourOperatorColumnVisible,
  children: [
    InventoryTacticCabinNegotiatedAllocationsColumn,
    ...Object.values(TO_CONTRACT_TYPE_VALUES).flatMap((contractType) => ({
      headerName: contractType,
      headerTooltip: `${t('grid.columns.tour_operator.contract_type.' + contractType)}`,
      groupId: `${TOUR_OPERATOR_FLIGHT_LEVEL_GROUP_ID_PREFIX}-${contractType}`,
      children: generateTourOperatorColumnsByContractType(contractType),
    })),
  ],
});

export const generateCabinNegotiatedAllocationsColumn = (cabinCode: string): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinNegoSpace),
  headerName: t('nego_space'),
  headerTooltip: t('negotiated_space'),
  type: 'numericColumn',
  width: 45,
  minWidth: 45,
  cellRenderer: 'GridNegotiatedAllocationsRenderer',
  sortable: true,
  valueGetter: (params) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin?.negotiatedAllocations?.totalSoldSeats;
  },
  cellRendererParams: (params: ICellRendererParams<FlightLineModel>) => {
    const maxAdjustedAllocation = params.data?.cabins?.find((cabin: FlightLineCabin) => cabin.code === cabinCode)?.minLegAuthorizedCapacity;
    return {
      cabinCode,
      openInModal: true,
      maxAdjustedAllocation,
    };
  },
  requiredPermission: isTourOperatorColumnVisible,
});

/**
 * Convenience method to generate all columns for all contract types on cabin level.
 */
const generateTourOperatorContractAggregationCabinLevelColumns = (cabins: CabinStructure[]): ColGroupDef<FlightLineModel>[] =>
  cabins.flatMap((cabin) => ({
    headerName: `${t('general.flight')} | ${t('cabin')} ${cabin.code} - ${t('grid.columns.tour_operator.tour_operator_contract_aggregations')}`,
    headerTooltip: `${t('general.flight')} | ${t('cabin')} ${cabin.code} - ${t('grid.columns.tour_operator.tour_operator_contract_aggregations')}`,
    groupId: `${TOUR_OPERATOR_CABIN_LEVEL_GROUP_ID_PREFIX}-${cabin.code}`,
    headerGroupComponentParams: {
      cabinCode: cabin.code,
    },
    requiredPermission: isTourOperatorColumnVisible,
    children: [
      generateCabinNegotiatedAllocationsColumn(cabin.code),
      ...Object.values(TO_CONTRACT_TYPE_VALUES).flatMap((contractType) => ({
        headerName: contractType,
        headerTooltip: `${t('grid.columns.tour_operator.contract_type.' + contractType)}`,
        groupId: `${TOUR_OPERATOR_CABIN_LEVEL_GROUP_ID_PREFIX}-${cabin.code}-${contractType}`,
        children: generateTourOperatorColumnsByContractType(contractType, cabin.code),
      })),
    ],
  }));

/**
 * Generate all the column groups for the tour operator contract aggregation on flight and cabin level.
 */
export const generateTourOperatorContractAggregationColumnGroups = ({
  cabins,
}: FlightLineColumnsGeneratorValuesInterface): ColGroupDef<FlightLineModel>[] => [
  generateTourOperatorContractAggregationFlightLevelColumns(),
  ...generateTourOperatorContractAggregationCabinLevelColumns(cabins),
];

/**
 * Tour Operator Contract Name Columns
 */
function generateColumnId(colId: string, tourOperator: AssignableTourOperatorContract, code?: CabinStructure['code']): string {
  const flightLevelColId = `tour-operator-name-${tourOperator.contractType}-${tourOperator.contractCode}-${colId}`;
  return code ? generateCabinLevelColumnId(code, flightLevelColId) : flightLevelColId;
}

function getTourOperatorColumnHeaderName(tourOperator: AssignableTourOperatorContract): string {
  return `${tourOperator.contractType} - ${tourOperator.contractCode}`;
}

function tourOperatorByContractNameValueGetter<K extends keyof TourOperatorDetailsByOperatorName>(
  key: K,
  tourOperator: AssignableTourOperatorContract,
  data?: FlightLineModel,
  code?: CabinCode,
): TourOperatorDetailsByOperatorName[K] | undefined {
  if (code) {
    const matchingCabin = FlightService.getMatchedCabin(data, code);
    return matchingCabin?.tourOperatorDetailsByContractTypeByContractCode?.[tourOperator.contractType]?.[tourOperator.contractCode]?.[key];
  } else {
    return data?.tourOperatorDetailsByContractTypeByContractCode?.[tourOperator.contractType]?.[tourOperator.contractCode]?.[key];
  }
}

const sumOfAllocCol = (tourOperator: AssignableTourOperatorContract, code?: CabinStructure['code']): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  headerName: t('grid.columns.tour_operator.sum_of_allocation_short'),
  headerTooltip: t('grid.columns.tour_operator.sum_of_allocation'),
  colId: generateColumnId('sum-of-allocation', tourOperator, code),
  sortable: true,
  valueGetter: (params) => tourOperatorByContractNameValueGetter('sumOfAllocation', tourOperator, params.data, code),
  requiredPermission: isTourOperatorColumnVisible,
});

const sumOfSoldCol = (tourOperator: AssignableTourOperatorContract, code?: CabinStructure['code']): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  headerName: t('grid.columns.tour_operator.sum_of_sold_short'),
  headerTooltip: t('grid.columns.tour_operator.sum_of_sold'),
  colId: generateColumnId('sum-of-sold', tourOperator, code),
  sortable: true,
  valueGetter: (params) => tourOperatorByContractNameValueGetter('sumOfSold', tourOperator, params.data, code),
  requiredPermission: isTourOperatorColumnVisible,
});

const seatAvailabilityCol = (tourOperator: AssignableTourOperatorContract, code?: CabinStructure['code']): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  headerName: t('grid.columns.tour_operator.seat_availability_short'),
  headerTooltip: t('grid.columns.tour_operator.seat_availability'),
  colId: generateColumnId('seat-availability', tourOperator, code),
  sortable: true,
  valueGetter: (params) => tourOperatorByContractNameValueGetter('seatAvailability', tourOperator, params.data, code),
  requiredPermission: isTourOperatorColumnVisible,
});

const materializationPercentageCol = (
  tourOperator: AssignableTourOperatorContract,
  code?: CabinStructure['code'],
): ColDef<FlightLineModel> => ({
  ...NumberColumnFilterSettings,
  headerName: t('grid.columns.tour_operator.materialization_percentage_short'),
  headerTooltip: t('grid.columns.tour_operator.materialization_percentage'),
  colId: generateColumnId('materialization-percentage', tourOperator, code),
  sortable: true,
  valueGetter: (params) => tourOperatorByContractNameValueGetter('materializationPercentage', tourOperator, params.data, code),
  requiredPermission: isTourOperatorColumnVisible,
});

/**
 * Generate all the column groups for per tour operator contract
 */
export const generateTourOperatorContractColumnGroups = ({
  cabins,
  tourOperators,
}: {
  cabins: FlightLineColumnsGeneratorValuesInterface['cabins'];
  tourOperators: FlightLineColumnsGeneratorValuesInterface['tourOperators'];
}): ColGroupDef<FlightLineModel>[] => [
  generateTourOperatorContractColumnGroupsColumns(tourOperators),
  ...generateTourOperatorContractCabinColumnGroupsColumns({ tourOperators, cabins }),
];

function generateTourOperatorContractColumnGroupsColumns(tourOperators: AssignableTourOperatorContract[]): ColGroupDef<FlightLineModel> {
  return {
    headerName: t('grid.columns.tour_operator.tour_operators'),
    groupId: 'tour_operators_by_contract_name',
    requiredPermission: isTourOperatorColumnVisible,
    children: tourOperators.map((tourOperator) => ({
      headerName: getTourOperatorColumnHeaderName(tourOperator),
      children: [
        sumOfAllocCol(tourOperator),
        sumOfSoldCol(tourOperator),
        seatAvailabilityCol(tourOperator),
        materializationPercentageCol(tourOperator),
      ],
    })),
  };
}

function generateTourOperatorContractCabinColumnGroupsColumns({
  cabins,
  tourOperators,
}: {
  cabins: CabinStructure[];
  tourOperators: AssignableTourOperatorContract[];
}): ColGroupDef<FlightLineModel>[] {
  return cabins.map(({ code }) => ({
    headerName: t('grid.columns.tour_operator.tour_operators_cabin', { code }),
    groupId: `tour_operators_by_cabin_by_contract_name_${code}`,
    requiredPermission: isTourOperatorColumnVisible,
    children: tourOperators.map((tourOperator) => ({
      headerName: getTourOperatorColumnHeaderName(tourOperator),
      children: [
        sumOfAllocCol(tourOperator, code),
        sumOfSoldCol(tourOperator, code),
        seatAvailabilityCol(tourOperator, code),
        materializationPercentageCol(tourOperator, code),
        {
          ...SetColumnFilterSettings,
          headerName: t('grid.columns.tour_operator.calculated_class_short'),
          headerTooltip: t('grid.columns.tour_operator.calculated_class'),
          colId: generateColumnId('calculated-class', tourOperator, code),
          sortable: true,
          valueGetter: (params) => tourOperatorByContractNameValueGetter('calculatedClass', tourOperator, params.data, code),
          requiredPermission: isTourOperatorColumnVisible,
        },
        {
          ...SetColumnFilterSettings,
          headerName: t('grid.columns.tour_operator.source_laf_class_short'),
          headerTooltip: t('grid.columns.tour_operator.source_laf_class'),
          colId: generateColumnId('source-laf-class', tourOperator, code),
          sortable: true,
          filter: 'agSetColumnFilter',
          valueGetter: (params) => tourOperatorByContractNameValueGetter('sourceLAFClass', tourOperator, params.data, code),
          requiredPermission: isTourOperatorColumnVisible,
        },
        {
          ...NumberColumnFilterSettings,
          headerName: t('grid.columns.tour_operator.laf_offset_short'),
          headerTooltip: t('grid.columns.tour_operator.laf_offset'),
          colId: generateColumnId('laf-offset', tourOperator, code),
          sortable: true,
          valueGetter: (params) => tourOperatorByContractNameValueGetter('lafOffset', tourOperator, params.data, code),
          requiredPermission: isTourOperatorColumnVisible,
        },
      ],
    })),
  }));
}
