export enum DefaultRouteManagementActionType {
  AssignUser = 'AssignUser',
  ReassignUser = 'ReassignUser',
  UnassignUser = 'UnassignUser',
  SetCFWindow = 'SetCFWindow',
  SetFareCurrency = 'SetFareCurrency',
  SetDirection = 'SetDirection',
  AssignRouteGroup = 'AssignRouteGroup',
  UnassignRouteGroup = 'UnassignRouteGroup',
  SetPriceRanges = 'SetPriceRanges',
  UserAssignment = 'UserAssignment',
  RouteAssignment = 'RouteAssignment',
  ClusterBulkAssign = 'ClusterBulkAssign',
  ForecastAndOptimisation = 'ForecastAndOptimisation',
  EnableForecastGeneration = 'EnableForecastGeneration',
  DisableForecastGeneration = 'DisableForecastGeneration',
  EnableForecastLearning = 'EnableForecastLearning',
  DisableForecastLearning = 'DisableForecastLearning',
  ReverseMarketName = 'ReverseMarketName',
  ConfigureMarketLabels = 'ConfigureMarketLabels',
  ManageMarketLabels = 'ManageMarketLabels',
}

export enum CreateMarketLabelsActionType {
  CreateMarketLabels_1 = 'CreateMarketLabels_1',
  CreateMarketLabels_2 = 'CreateMarketLabels_2',
  CreateMarketLabels_3 = 'CreateMarketLabels_3',
}

export enum AssignMarketLabelActionType {
  AssignMarketLabel_1 = 'AssignMarketLabel_1',
  AssignMarketLabel_2 = 'AssignMarketLabel_2',
  AssignMarketLabel_3 = 'AssignMarketLabel_3',
}

export enum UnassignMarketLabelActionType {
  UnassignMarketLabel_1 = 'UnassignMarketLabel_1',
  UnassignMarketLabel_2 = 'UnassignMarketLabel_2',
  UnassignMarketLabel_3 = 'UnassignMarketLabel_3',
}

export enum OpLevelAdjustmentIncrementActionType {
  BYOROpLevelAdjustIncrement = 'BYOROpLevelAdjustIncrement',
  DynamicProgramOpLevelAdjustIncrement = 'DynamicProgramOpLevelAdjustIncrement',
}

export const RouteManagementActions = {
  ...DefaultRouteManagementActionType,
  ...OpLevelAdjustmentIncrementActionType,
  ...CreateMarketLabelsActionType,
  ...AssignMarketLabelActionType,
  ...UnassignMarketLabelActionType,
};
export type RouteManagementActionTypeKey = keyof typeof RouteManagementActions;
export type RouteManagementActionType = (typeof RouteManagementActions)[RouteManagementActionTypeKey];
