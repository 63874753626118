import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface UnassignMarketLabelPayload extends RouteActionPayload {
  series: number;
}

export class UnassignMarketLabelAction implements RouteAction<UnassignMarketLabelPayload> {
  public actionType: RouteActionType = RouteActionType.unassignMarketLabel;

  public series: number;

  constructor(series: number) {
    this.series = series;
  }

  public setPayload(payload: UnassignMarketLabelPayload): void {
    this.series = payload.series;
  }

  public getPayload(): UnassignMarketLabelPayload {
    return {
      actionType: this.actionType,
      series: this.series,
    };
  }
}
