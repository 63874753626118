<template>
  <extended-ag-grid-vue
    ref="gridRef"
    :auto-group-column-def="autoGroupColumnDef"
    :column-defs="colDefs"
    :grid-options="internalGridOptions"
    :row-data="rowData"
    :side-bar="sideBar"
    :accented-sort="accentedSort"
    :agg-funcs="aggFunctions"
    :pinned-top-row-data="pinnedTopRowData"
    :post-sort-rows="postSortRows"
    :class="{
      'ag-theme-alpine': true,
      [classes]: true,
      disabled,
    }"
    @grid-ready="onGridReady"
    @grid-size-changed="onGridSizeChanged"
  />
</template>

<script lang="ts" setup generic="TData extends any, TContext extends any">
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  IAggFunc,
  PostSortRowsParams,
  RowDoubleClickedEvent,
  SideBarDef,
} from 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';
import { isEmpty, merge } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { ComputedRef, Ref, computed, onBeforeMount, ref, watch } from 'vue';

import ExtendedAgGridVue from '@/modules/grid/components/ExtendedAgGridVue.vue';
import GridUserActionsRenderer from '@/modules/grid/components/GridUserActionsRenderer.vue';
import { useUserStore } from '@/modules/user-settings/store/user.store';

interface KGridProps {
  colDefs: ColDef<TData>[];
  rowData: TData[];
  accentedSort?: boolean;
  aggFunctions?: { [key: string]: IAggFunc };
  autoGroupColumnDef?: ColDef<TData>;
  autoResizeColumns?: boolean;
  autoSizeColumns?: boolean;
  classes?: string;
  disabled?: boolean;
  gridOptions?: GridOptions<TData>;
  pinnedTopRowData?: TData[];
  postSortRows?: (params: PostSortRowsParams<TData>) => void;
  sideBar?: SideBarDef;
  spacious?: boolean;
}

defineOptions({
  components: {
    GridUserActionsRenderer,
  },
});

const props = withDefaults(defineProps<KGridProps>(), {
  colDefs: () => [],
  rowData: () => [],
  autoGroupColumnDef: undefined,
  sideBar: undefined,
  gridOptions: undefined,
  accentedSort: false,
  aggFunctions: undefined,
  pinnedTopRowData: undefined,
  postSortRows: undefined,
  spacious: false,
  disabled: false,
  autoResizeColumns: true,
  autoSizeColumns: true,
  classes: 'no-status-bar w-full h-full',
});

const emit = defineEmits<{
  (e: 'gridReady', v: GridReadyEvent<TData, TContext>): void;
  (e: 'rowDoubleClicked', v: RowDoubleClickedEvent<TData, TContext>): void;
  (e: 'gridSizeChanged', v: GridSizeChangedEvent<TData, TContext>): void;
}>();

const userStore = useUserStore();
const { fontSize } = storeToRefs(userStore);

const gridRowHeightAdjustment: ComputedRef<number> = computed(() => (props.spacious ? 24 : 11));
const fontSizeWithUnit: ComputedRef<string> = computed(() => `${fontSize.value}px`);

watch(fontSize, changeRowHeight);

const baseGridOptions: GridOptions<TData> = {
  rowHeight: fontSize.value + gridRowHeightAdjustment.value,
  domLayout: 'autoHeight',
  scrollbarWidth: 15,
  multiSortKey: 'ctrl',
  onRowDoubleClicked: (event) => emit('rowDoubleClicked', event),
  defaultColDef: {
    cellStyle: () => ({
      'font-size': `${fontSize.value}px;`,
      'line-height': `${fontSize.value + gridRowHeightAdjustment.value - 2}px`,
    }),
    headerClass: 'text-only-header',
    resizable: true,
    sortable: true,
  },
};

const gridRef: Ref<InstanceType<typeof AgGridVue> | undefined> = ref();
const internalGridOptions: Ref<GridOptions<TData> | undefined> = ref();
const internalGridApi: Ref<GridApi<TData> | undefined> = ref();

onBeforeMount(() => {
  internalGridOptions.value = !isEmpty(props.gridOptions) ? merge(baseGridOptions, props.gridOptions) : baseGridOptions;
});

function isVisible(): boolean {
  return !!gridRef.value?.$el.offsetParent;
}

function changeRowHeight(val: number): void {
  internalGridApi.value?.setGridOption('rowHeight', val + gridRowHeightAdjustment.value);
  internalGridApi.value?.resetRowHeights();
}

function onGridSizeChanged(e: GridSizeChangedEvent<TData, TContext>): void {
  if (props.autoResizeColumns && isVisible()) {
    internalGridApi.value.sizeColumnsToFit();
  }

  emit('gridSizeChanged', e);
}

function onGridReady(e: GridReadyEvent<TData, TContext>): void {
  internalGridApi.value = e.api;

  if (props.autoSizeColumns && isVisible()) {
    internalGridApi.value.sizeColumnsToFit();
  }

  emit('gridReady', e);
}
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

// Overwrite the menu to be on top of a modal if the table is rendered inside a modal
.ag-theme-alpine.ag-popup {
  > .ag-menu {
    z-index: 10000;
  }
  > .ag-list {
    z-index: 10001;
  }
  > .ag-popup-child {
    z-index: 10000;
  }
}

.ag-theme-alpine {
  --ag-font-size: v-bind(fontSizeWithUnit);
}
</style>
