import { defineStore } from 'pinia';
import { ref } from 'vue';

import { UserGroup } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { userGroupManagementService } from '@/modules/user-management/api/user-group/user-group-management.service';

export const useUserGroupStore = defineStore('userGroup', () => {
  const userGroups = ref<UserGroup[]>([]);

  async function getUserGroups(): Promise<void> {
    userGroups.value = await userGroupManagementService.get();
  }

  return {
    userGroups,
    getUserGroups,
  };
});
