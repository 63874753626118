import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface AssignMarketLabelPayload extends RouteActionPayload {
  label: string;
  series: number;
}

export class AssignMarketLabelAction implements RouteAction<AssignMarketLabelPayload> {
  public actionType: RouteActionType = RouteActionType.assignMarketLabel;

  public label: string;
  public series: number;

  constructor(label: string, series: number) {
    this.label = label;
    this.series = series;
  }

  public setPayload(payload: AssignMarketLabelPayload): void {
    this.label = payload.label;
    this.series = payload.series;
  }

  public getPayload(): AssignMarketLabelPayload {
    return {
      actionType: this.actionType,
      label: this.label,
      series: this.series,
    };
  }
}
