import { RouteLocationRaw } from 'vue-router';

export interface BreadCrumbInterface {
  displayName: string;
  to: RouteLocationRaw;
}

export enum RouteName {
  ancillaryCodesManagement = 'AncillaryCodesManagement',
  appDefault = 'app-default',
  archivedOverview = 'flows-archived',
  clusterManagement = 'cluster-management',
  continuousPricing = 'continuous-pricing',
  control = 'control',
  customerConfig = 'customer-config',
  dashboard = 'dashboard',
  dataImport = 'data-import',
  departed = 'departed',
  details = 'details',
  docs = 'docs',
  eventManagementExclusions = 'event-management-exclusions',
  eventManagementInclusions = 'event-management-inclusions',
  eventManagementOverview = 'event-management-overview',
  features = 'features',
  flowCreate = 'flow-create',
  flowDetails = 'flow-details',
  flowFx = 'flowFx',
  flowsOverview = 'flows-overview',
  insights = 'insights',
  insightsDetails = 'insights-details',
  linkedClassRulesCreate = 'linked-class-rules-create',
  linkedClassRulesDetails = 'linked-class-rules-details',
  linkedClassRulesOverview = 'linked-class-rules-overview',
  login = 'login',
  pacingCurveCreate = 'pacing-curve-create',
  pacingCurveEdit = 'pacing-curve-edit',
  pacingCurveManagement = 'pacing-curve-management',
  promotionCreate = 'promotion-create',
  promotionEdit = 'promotion-edit',
  promotionManagement = 'promotion-management',
  releaseNotes = 'release-notes',
  reportingCreate = 'reporting-create',
  reportingEdit = 'reporting-edit',
  reportingListing = 'reporting-listing',
  revenueComponentManagement = 'RevenueComponentsManagement',
  review = 'review',
  rivalRulesCreate = 'rival-rules-create',
  rivalRulesDetails = 'rival-rules-details',
  rivalRulesOverview = 'rival-rules-overview',
  routeManagement = 'route-management',
  routineCreate = 'routine-create',
  routineDetails = 'routine-details',
  routinesOverview = 'flows-routines',
  rulesOverview = 'rules-overview',
  surchargeCodesManagement = 'SurchargeCodesManagement',
  system = 'system',
  taxCodesManagement = 'TaxCodesManagement',
  user = 'user',
  userChangePassword = 'user-change-password',
  userGuide = 'user-guide',
  userManagement = 'user-management',
  userManagementUsers = 'user-management-users',
  userManagementUserGroups = 'user-management-user-groups',
}
