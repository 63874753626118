<template>
  <div>
    <div class="flex h-full items-center">{{ value }}</div>

    <el-link class="text-xs font-semibold" type="primary" @click="onAddSameLevelClicked">
      {{ t('user_management.actions.add_same_level') }}
    </el-link>
    <el-link v-if="!props.params?.data?.isLeaf" class="text-xs font-semibold ml-4" type="primary" @click="onAddNestedLevelClicked">
      {{ t('user_management.actions.add_nested_level') }}
    </el-link>
  </div>
</template>

<script lang="ts" setup>
import { ICellRendererParams } from 'ag-grid-enterprise';
import { clone } from 'lodash-es';
import { onMounted, ref } from 'vue';

import { UserGroup } from '@/modules/user-management/api/user-group/user-group-management.contracts';
import { UserGroupNameCellInnerRendererParams } from '@/modules/user-management/models/column-definitions/user-group-management-column-definitions';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

const props = defineProps<{
  params: ICellRendererParams<UserGroup> & UserGroupNameCellInnerRendererParams;
}>();
const value = ref<UserGroup | undefined>();

onMounted(() => {
  setValue(props.params);
});

// AG Grid hook not recognized by TS
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function refresh(params: typeof props.params): void {
  setValue(params);
}

function setValue(params: typeof props.params): void {
  value.value = params.value;
}

function onAddSameLevelClicked(): void {
  if (!props.params.data) {
    throw new Error('User group is missing');
  }
  const newUserGroup: UserGroup = {
    ...props.params.data,
    id: undefined,
    name: `${props.params.data.name} [copy]`,
    alias: `${props.params.data.alias} [copy]`,
  };
  props.params.onAddUserGroup(newUserGroup);
}

function onAddNestedLevelClicked(): void {
  if (!props.params.data?.id) {
    throw new Error('User group ID is missing');
  }
  const newUserGroup: UserGroup = {
    ...props.params.data,
    id: undefined,
    parent: { id: clone(props.params.data.id), name: clone(props.params.data.name) },
    name: `${clone(props.params.data.name)} [copy]`,
    alias: `${clone(props.params.data.alias)} [copy]`,
  };
  props.params.onAddUserGroup(newUserGroup);
}
</script>
